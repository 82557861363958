export const sourceUrl = "https://www.ntmwd.com/";
export const subdomainUrl = "https://news.ntmwd.com";
export const twitter = "@NTMWD";
export const siteTitle = "NTMWD";

export const stopGapRelativeToAbsolutePaths = (content) => {
  return content && typeof content === "string"
    ? content.replace(/href="\//g, 'href="' + sourceUrl)
    : null;
};

/* CPT SPECIFIC BUT DOESNT HANDLE PAGES AT THE MOMMENT
export const stopGapRelativeToAbsolutePaths = (content) => {
  let _content = content.replace(
    /href="\/documents\//g,
    'href="' + sourceUrl + "documents/"
  );
  _content = content.replace(
    /href="\/event\//g,
    'href="' + sourceUrl + "event/"
  );
  return _content;
};
*/
